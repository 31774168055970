@font-face {
  font-family: "Grotesk";
  src: url("./style/GroteskRegular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Grotesk";
  font-weight: 500;
  font-display: swap;
  src: url("./style/GroteskMedium.woff") format("woff");
}

@font-face {
  font-family: "Grotesk";
  font-weight: 700;
  font-display: swap;
  src: url("./style/GroteskBold.woff") format("woff");
}

@font-face {
  font-family: "Roslindale";
  font-weight: 700;
  font-display: swap;
  src: url("./style/RoslindaleDeckNarrowBold.woff2") format("woff2"),
    url("./style/RoslindaleDeckNarrowBold.woff") format("woff");
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  min-height: 100vh;
  display: flex;
  align-content: stretch;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

input,
button,
select,
option {
  font-family: inherit;
}
